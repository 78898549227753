<template>
  <div class="profile">
    <div class="text-sm-h5 text-h6 font-weight-bold">My Profile</div>
    <v-card
      outlined
      v-if="!data.is_email_confirmed"
      class="mt-5 px-7 py-5 rounded-lg d-flex align-center warning lighten-4"
      style="border-color: var(--v-warning-base) !important"
    >
      <v-icon color="warning">mdi-alert</v-icon>
      <div class="gray--text f18 font-weight-medium ms-4">Email is not confirmed</div>
      <v-spacer></v-spacer>
      <v-btn @click="sendEmail" height="30" text color="warning">
        <v-icon class="me-2">mdi-restore mdi-flip-h mdi-rotate-45</v-icon><span class="text-600 f15">Resend Confirmation</span>
      </v-btn>
    </v-card>
    <v-card outlined class="mt-5 px-7 py-6 rounded-lg d-flex">
      <v-avatar size="100" color="gray lighten-2">
        <v-img v-if="data.picture === null" cover src="@/assets/icon/empty-user.svg" alt="avatar"></v-img>
        <v-img v-else cover :src="data.picture" alt="avatar"></v-img>
      </v-avatar>
      <div class="ms-5 mt-1">
        <div class="f18 font-weight-bold">{{ data.first_name }} {{ data.last_name }}</div>
        <div class="f15 font-weight-medium gray--text">{{ data.email }}</div>
        <div class="f15 font-weight-medium gray--text text-capitalize">{{ data.role }}</div>
      </div>
      <v-spacer></v-spacer>
      <v-btn @click="editModal = true" outlined class="mt-1" color="primary" large width="160">
        <span class="f15 text-600">Edit Profile</span>
      </v-btn>
    </v-card>
    <v-card outlined class="mt-5 px-7 py-6 rounded-lg d-flex">
      <div>
        <div class="text-h6 font-weight-bold">Password settings</div>
        <div class="gray--text f15 font-weight-medium">You can reset user password, it will be sent to the Staff email</div>
      </div>
      <v-spacer></v-spacer>
      <v-btn @click="editPass = true" outlined class="mt-1" color="primary" large width="160">
        <span class="f15 text-600">Change password</span>
      </v-btn>
    </v-card>
    <v-card outlined class="mt-5 px-7 py-6 rounded-lg d-flex">
      <div>
        <div class="text-h6 font-weight-bold">Two factor authentication</div>
        <div class="gray--text f15 font-weight-medium mt-1" style="max-width: 500px">
          Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to log in
        </div>
      </div>
      <v-spacer></v-spacer>
      <div class="text-md-right text-center">
        <v-btn depressed v-if="data['2fa_enabled']" class="me-3" width="160" large color="gray lighten-2" @click="codeModal = true">
          <img class="me-2" src="@/assets/icon/show.svg" alt="show" /><span class="f15 font-weight-medium">View codes</span>
        </v-btn>
        <v-btn depressed v-if="!data['2fa_enabled']" width="160" large color="primary" @click="openEnable2FA">
          <span class="f15 font-weight-bold">Enable 2FA</span>
        </v-btn>
        <v-btn outlined class="error-btn" color="error" v-else width="160" large @click="disable">
          <span class="f15 text-600">Disable 2FA</span>
        </v-btn>
      </div>
    </v-card>
    <v-dialog v-model="setupModal" max-width="600">
      <v-card>
        <v-btn @click="setupModal = false" icon small class="px-0 close-btn"><v-icon>mdi-close</v-icon></v-btn>
        <v-window touchless v-model="progress">
          <v-window-item :value="1" class="pt-5 pb-7 px-7">
            <div class="text-h5 font-weight-bold">Set Up 2FA: Recovery codes</div>
            <div class="f15 gray--text mt-1" style="max-width: 500px">
              Treat your recovery codes with the same level of security as you would your password. We recommend saving them with a password
              manager such as Lastpass, 1Password, or Keeper
            </div>
            <div class="d-flex align-center mt-5">
              <v-icon color="warning">mdi-alert mdi-36px</v-icon>
              <div class="gray--text text-600 f15 ms-4">
                <span class="black--text font-weight-bold">Note:</span>
                If you lose access to your 2FA application/device and don’t have the recovery codes - you will lose access to your account.
              </div>
            </div>
            <div class="d-flex flex-wrap mt-7">
              <div v-for="(item, i) in codes.codes" :key="item + i" class="text-center f18 font-weight-bold" style="width: 50%">
                {{ item }}
              </div>
            </div>
          </v-window-item>
          <v-window-item :value="2" class="pt-5 pb-4">
            <div class="px-7">
              <div class="text-h5 font-weight-bold">Set Up 2FA: App/device synchronization</div>
              <div class="f15 gray--text mt-1" style="max-width: 500px">
                Scan the QR code with your 2FA application/device or scan the code manualy in your application
              </div>
              <v-img class="mx-auto my-10" width="180" :src="qr.qr_code"></v-img>
              <v-text-field
                class="field48 center-input px-16"
                v-model="qr.text_code"
                outlined
                dense
                required
                color="primary"
                height="48"
              ></v-text-field>
            </div>
            <v-divider class="mb-6"></v-divider>
            <div class="text-center px-3">Enter the OTP from your 2FA application/device</div>
            <div class="mx-auto mt-2 px-7">
              <v-text-field
                v-model="code"
                class="field48 center-input px-16"
                :error-messages="codeErrors"
                outlined
                dense
                required
                color="primary"
                maxlength="6"
                height="48"
              ></v-text-field>
            </div>
          </v-window-item>
        </v-window>
        <v-divider></v-divider>
        <div class="d-flex justify-space-between pa-7">
          <div v-if="progress != 2">
            <v-btn depressed width="135" height="42" color="gray lighten-2" @click="downloadCodes">
              <v-icon left color="gray">mdi-cloud-download</v-icon>
              <span class="gray--text f15 font-weight-medium">Download</span>
            </v-btn>
            <v-btn depressed width="100" height="42" color="gray lighten-2" class="ms-3" @click="copy">
              <v-icon left color="gray">mdi-file-document-multiple</v-icon>
              <span class="gray--text f15 font-weight-medium">Copy</span>
            </v-btn>
          </div>
          <v-btn v-else @click="progress = 1" height="42" text>
            <v-icon color="primary darken-2" left>mdi-arrow-top-left mdi-rotate-315 mdi-18px</v-icon>
            <span class="f15 text-600 primary--text text--darken-2">Step Back</span>
          </v-btn>
          <v-btn depressed v-if="progress != 2" width="150" height="42" color="primary" @click="step2">
            <span class="f15 text-600">Next Step</span>
          </v-btn>
          <v-btn depressed v-else width="150" height="42" color="primary" @click="enable">
            <span class="f15 text-600">Confirm</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="codeModal" max-width="600">
      <v-card>
        <div class="pt-5 pb-7 px-7">
          <v-btn @click="codeModal = false" icon small class="px-0 close-btn"><v-icon>mdi-close</v-icon></v-btn>
          <div class="text-h5 font-weight-bold">Recovery codes</div>
          <div class="d-flex align-center mt-4">
            <v-icon color="warning">mdi-alert mdi-36px</v-icon>
            <div class="gray--text text-600 f15 ms-4">
              <span class="black--text font-weight-bold">Note:</span>
              If you lose access to your 2FA application/device and don’t have the recovery codes - you will lose access to your account.
            </div>
          </div>
          <div class="d-flex flex-wrap mt-7">
            <div v-for="(item, i) in codes.codes" :key="item + i" class="text-center f18 font-weight-bold" style="width: 50%">
              {{ item }}
            </div>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="d-flex align-center pa-7">
          <v-btn depressed width="135" height="42" color="gray lighten-2" @click="downloadCodes">
            <v-icon left color="gray">mdi-cloud-download</v-icon>
            <span class="gray--text f15 font-weight-medium">Download</span>
          </v-btn>
          <v-btn depressed width="100" height="42" color="gray lighten-2" class="ms-3" @click="copy">
            <v-icon left color="gray">mdi-file-document-multiple</v-icon>
            <span class="gray--text f15 font-weight-medium">Copy</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn depressed width="180" height="42" color="primary" @click="generate">
            <span class="f15 text-600">Generate new codes</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editModal" max-width="600">
      <v-card class="pt-5 pb-12 px-3">
        <v-btn @click="editModal = false" icon small class="px-0 close-btn"><v-icon>mdi-close</v-icon></v-btn>
        <div class="mx-auto" style="max-width: 400px">
          <div class="text-center text-h5 font-weight-bold mb-7">Edit Profile</div>
          <v-hover v-slot="{ hover }">
            <v-avatar @click="changeAvatar" size="80" color="gray lighten-2" class="mx-auto d-block link">
              <v-img v-if="data.picture === null && !image" cover src="@/assets/icon/empty-user.svg" alt="avatar"></v-img>
              <v-img v-else cover :src="image || data.picture" alt="avatar"></v-img>
              <div :class="{ 'hover-ava': hover }" class="d-none white--text">Edit</div>
            </v-avatar>
          </v-hover>
          <div class="primary--text text--darken-2 mt-5">First name</div>
          <v-text-field
            class="field48"
            v-model="data.first_name"
            :error-messages="firstnameErrors"
            outlined
            dense
            required
            color="primary"
            height="48"
            maxlength="50"
          ></v-text-field>
          <div class="primary--text text--darken-2 mt-n2">Last name</div>
          <v-text-field
            class="field48"
            v-model="data.last_name"
            :error-messages="lastnameErrors"
            outlined
            dense
            required
            color="primary"
            height="48"
            maxlength="50"
          ></v-text-field>
          <div class="primary--text text--darken-2 mt-n2">Email Address</div>
          <v-text-field
            class="field48"
            v-model="data.email"
            :error-messages="emailErrors"
            outlined
            dense
            required
            height="48"
            color="primary"
          ></v-text-field>
          <div class="d-flex mt-4">
            <v-btn depressed class="me-2 width100" max-width="190" height="48" color="gray lighten-2" @click="editModal = false">
              <span class="f15 font-weight-bold gray--text">Cancel</span>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn depressed class="ms-2 width100" max-width="190" height="48" color="primary" @click="update">
              <span class="text-body-1 font-weight-bold">Save</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editPass" max-width="600">
      <v-card class="pt-5 pb-12 px-3">
        <v-btn @click="editPass = false" icon small class="px-0 close-btn"><v-icon>mdi-close</v-icon></v-btn>
        <div class="mx-auto" style="max-width: 400px">
          <div class="text-center text-h5 font-weight-bold mb-10">Change password</div>
          <div class="primary--text text--darken-2">Current password</div>
          <v-text-field
            class="field48"
            v-model="pass.currentPassword"
            placeholder="Password"
            :error-messages="currentPasswordErrors"
            :type="show ? 'text' : 'password'"
            outlined
            dense
            required
            color="primary"
            height="48"
            maxlength="50"
          >
            <template v-slot:append>
              <img class="link" @click.stop="show = !show" :src="require(`@/assets/icon/${show ? 'show' : 'hide'}.svg`)" alt="icon" />
            </template>
          </v-text-field>
          <div class="primary--text text--darken-2 mt-n2">New password</div>
          <v-text-field
            class="field48"
            v-model="pass.newPas"
            placeholder="New password"
            :error-messages="newPasErrors"
            :type="showNew ? 'text' : 'password'"
            outlined
            dense
            required
            color="primary"
            height="48"
            maxlength="50"
          >
            <template v-slot:append>
              <img
                class="link"
                @click.stop="showNew = !showNew"
                :src="require(`@/assets/icon/${showNew ? 'show' : 'hide'}.svg`)"
                alt="icon"
              />
            </template>
          </v-text-field>
          <div class="d-flex mt-4">
            <v-btn depressed class="me-2 width100" max-width="190" height="48" color="gray lighten-2" @click="editPass = false">
              <span class="f15 font-weight-bold gray--text">Cancel</span>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn depressed class="ms-2 width100" max-width="190" height="48" color="primary" @click="newPassword">
              <span class="text-body-1 font-weight-bold">Save</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      setupModal: false,
      codeModal: false,
      editModal: false,
      editPass: false,
      show: false,
      showNew: false,
      file: '',
      image: '',
      code: null,
      progress: 1,
      pass: {
        currentPassword: null,
        newPas: null,
      },
      url: window.origin,
      token: sessionStorage.getItem('jwt_token'),
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getCodes');
  },
  methods: {
    notifi(btn) {
      if (btn == 'copy') {
        this.$notify({ message: 'Recovery codes were copied to your clipboard', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'confirm') {
        this.$notify({ message: 'Email confirmation was sent successfully', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'newPass') {
        this.$notify({ message: 'Password was successfully updated', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'updatePhoto') {
        this.$notify({ message: 'Your profile picture was successfully updated', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'update') {
        this.$notify({ message: 'Profile was successfully updated', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'copyError') {
        this.$notify({ message: 'Sorry, error copied', type: 'error', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'generate') {
        this.$notify({ message: 'New recovery codes. Old codes doesn’t work anymore.', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (this.error.find((i) => i == 'confirmation__too_often')) {
        this.$notify({
          message: 'Sorry, but email confirmation requests limit exceeded. Try again later',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (this.error.find((i) => i == 'email__already_confirmed')) {
        this.$notify({ message: 'Email is already confirmed', type: 'warning', horizontalAlign: 'center', verticalAlign: 'top' });
      }
    },
    async update() {
      this.error = [];
      const data = new Object();
      data.first_name = this.data.first_name;
      data.last_name = this.data.last_name;
      data.email = this.data.email;
      await this.$store
        .dispatch('updateProfile', data)
        .then(() => {
          this.editModal = false;
          this.notifi('update');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    changeAvatar() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.file = this.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            self.image = e.target.result;
            self.updatePhote();
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    async updatePhote() {
      const formData = new FormData();
      this.file ? formData.append('picture', this.file) : '';
      await this.$store.dispatch('setPhoto', formData).then(() => {
        this.file = '';
        this.notifi('updatePhoto');
      });
    },
    async deletePhote() {
      await this.$store.dispatch('deletePhoto').then(() => {
        this.image = '';
        this.$store.dispatch('getProfile');
      });
    },
    async sendEmail() {
      await this.$store
        .dispatch('resendEmailProfile')
        .then(() => {
          this.notifi('confirm');
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.notifi();
        });
    },
    copy() {
      const string = this.codes.codes.join('\n');
      const container = document.querySelector('.v-dialog--active');
      this.$copyText(string, container)
        .then(() => {
          this.notifi('copy');
        })
        .catch(() => {
          this.notifi('copyError');
        });
    },
    async generate() {
      await this.$store.dispatch('generate').then(() => {
        this.notifi('generate');
      });
    },
    async step2() {
      await this.$store.dispatch('step2').then(() => {
        this.progress = 2;
      });
    },
    async newPassword() {
      this.error = [];
      const data = new Object();
      data.current_password = this.pass.currentPassword;
      data.new_password = this.pass.newPas;
      await this.$store
        .dispatch('newPasswordProfile', data)
        .then(() => {
          this.editPass = false;
          this.notifi('newPass');
        })
        .catch((e) => {
          this.error = e.response.data.error;
          if (this.error.find((i) => i == 'account__suspended')) {
            this.$router.push('/account-blocked');
          }
        });
    },
    async openEnable2FA() {
      // await this.$store.dispatch('generate');
      this.setupModal = true;
    },
    async enable() {
      await this.$store
        .dispatch('enable', this.code)
        .then(() => {
          this.setupModal = false;
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.notifi();
        });
    },
    async disable() {
      await this.$store.dispatch('disable');
    },
    async downloadCodes() {
      await this.$store.dispatch('downloadCodes');
    },
  },
  watch: {
    setupModal() {
      this.code = null;
      this.progress = 1;
      this.error = [];
    },
    editModal() {
      this.file = '';
      this.image = '';
      this.error = [];
    },
    editPass() {
      this.pass = {
        currentPassword: null,
        newPas: null,
      };
      this.error = [];
    },
  },
  computed: {
    data() {
      return this.$store.getters.profile;
    },
    codes() {
      return this.$store.getters.codes;
    },
    qr() {
      return this.$store.getters.qr;
    },
    firstnameErrors() {
      const errors = [];
      this.error.find((i) => i == 'first_name__required') && errors.push('Please enter your first name');
      this.error.find((i) => i == 'first_name__invalid') && errors.push('Provided first name is not valid');
      return errors;
    },
    lastnameErrors() {
      const errors = [];
      this.error.find((i) => i == 'last_name__required') && errors.push('Please enter your last name');
      this.error.find((i) => i == 'last_name__invalid') && errors.push('Provided last name is not valid');
      return errors;
    },
    emailErrors() {
      const errors = [];
      this.error.find((i) => i == 'email__required') && errors.push('Please enter your email address');
      this.error.find((i) => i == 'email__invalid') && errors.push('Provided email address is not valid');
      this.error.find((i) => i == 'email__exists') && errors.push('Provided email address is already registered. Please use another one');
      return errors;
    },
    codeErrors() {
      const errors = [];
      this.error.find((i) => i == 'code__required') && errors.push('Please enter OTP');
      this.error.find((i) => i == 'code__invalid') && errors.push('Provided OTP is not valid. Please check and try again');
      this.error.find((i) => i == 'code__wrong') && errors.push('Provided OTP is not correct. Please check and try again');
      this.error.find((i) => i == 'otp__wrong') && errors.push('Provided OTP is not correct. Please check and try again');
      return errors;
    },
    currentPasswordErrors() {
      const errors = [];
      this.error.find((i) => i == 'current_password__required') && errors.push('Please enter current password');
      this.error.find((i) => i == 'current_password__invalid') &&
        errors.push('Current password is not correct. Please check and try again');
      return errors;
    },
    newPasErrors() {
      const errors = [];
      this.error.find((i) => i == 'new_password__required') && errors.push('Please enter new password');
      this.error.find((i) => i == 'new_password__invalid') &&
        errors.push('Please use at least one uppercase letter, one digit, and one of the allowed symbols: ~!@#$%^&*()_+-=|:<>?,.');
      return errors;
    },
  },
};
</script>
