<template>
  <div class="profile">
    <div class="text-sm-h5 text-h6 font-weight-bold">
      {{ data.first_name ? data.first_name : 'Unknown' }} {{ data.last_name ? data.last_name : 'User' }}
    </div>
    <v-card
      outlined
      v-if="!data.is_email_confirmed"
      class="mt-5 px-7 py-5 rounded-lg d-flex align-center warning lighten-4"
      style="border-color: var(--v-warning-base) !important"
    >
      <v-icon color="warning">mdi-alert</v-icon>
      <div class="gray--text f18 font-weight-medium ms-4">Email is not confirmed</div>
      <v-spacer></v-spacer>
      <v-btn @click="sendEmail" height="30" text color="warning">
        <v-icon class="me-2">mdi-restore mdi-flip-h mdi-rotate-45</v-icon><span class="text-600 f15">Resend Confirmation</span>
      </v-btn>
    </v-card>
    <v-card outlined class="mt-5 px-7 py-6 rounded-lg d-flex">
      <v-avatar size="100" color="gray lighten-2">
        <v-img v-if="data.picture === null" cover src="@/assets/icon/empty-user.svg" alt="avatar"></v-img>
        <v-img v-else cover :src="data.picture" alt="avatar"></v-img>
      </v-avatar>
      <div class="ms-5 mt-1">
        <div class="text-sm-h5 text-h6 font-weight-bold">{{ data.first_name }} {{ data.last_name }}</div>
        <div class="f15 font-weight-medium gray--text">{{ data.email }}</div>
        <div class="f15 font-weight-medium gray--text text-capitalize">{{ data.role }}</div>
      </div>
      <v-spacer></v-spacer>
      <v-btn @click="editModal = true" outlined class="mt-1" color="primary" large width="160">
        <span class="f15 text-600">Edit Profile</span>
      </v-btn>
    </v-card>
    <v-card outlined class="mt-5 px-7 py-6 rounded-lg d-flex">
      <div>
        <div class="text-h6 font-weight-bold">Password settings</div>
        <div class="gray--text f15 font-weight-medium">You can reset user password, it will be sent to the Staff email</div>
      </div>
      <v-spacer></v-spacer>
      <v-btn @click="resetModal = true" depressed class="mt-1" color="primary" large width="160">
        <span class="f15 text-600">Reset Password</span>
      </v-btn>
    </v-card>
    <v-card outlined class="mt-5 px-7 py-6 rounded-lg d-flex align-center">
      <div class="text-h6 font-weight-bold">Two factor authentication</div>
      <v-spacer></v-spacer>
      <div class="f18 gray--text d-flex align-center">
        <v-icon class="me-3" :color="data['2fa_enabled'] ? 'success' : 'error'">mdi-circle mdi-18px</v-icon>
        {{ data['2fa_enabled'] ? 'Enable' : 'Dissable' }}
      </div>
    </v-card>
    <v-card outlined class="mt-5 px-7 py-6 rounded-lg d-flex">
      <div>
        <div class="text-h6 font-weight-bold">Deactivate account</div>
        <div class="gray--text f15 font-weight-medium">You can deactivate the account to restrict its access to the platform.</div>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        @click="activeModal = true"
        outlined
        class="mt-1"
        :class="data.is_suspended ? 'success-btn' : 'error-btn'"
        :color="data.is_suspended ? 'success' : 'error'"
        large
        width="160"
      >
        <span class="f15 text-600">{{ data.is_suspended ? 'Activate Account' : 'Deactivate Account' }}</span>
      </v-btn>
    </v-card>
    <v-dialog v-model="activeModal" max-width="480">
      <v-card class="pa-5">
        <v-btn @click="activeModal = false" icon small class="px-0 close-btn"><v-icon>mdi-close</v-icon></v-btn>
        <div class="text-h6 font-weight-bold me-10">
          Do you really want to {{ data.is_suspended ? 'activate' : 'deactivate' }} {{ data.first_name }} account?
        </div>
        <div class="text-end mt-7">
          <v-btn depressed class="me-2 width100" max-width="120" height="38" color="gray lighten-2" @click="activeModal = false">
            <span class="f15 font-weight-bold gray--text">Cancel</span>
          </v-btn>
          <v-btn
            outlined
            class="ms-2 width100"
            max-width="120"
            height="38"
            :class="data.is_suspended ? 'success-btn' : 'error-btn'"
            :color="data.is_suspended ? 'success' : 'error'"
            @click="active"
          >
            <span class="f15 font-weight-bold">{{ data.is_suspended ? 'Activate' : 'Deactivate' }}</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="resetModal" max-width="480">
      <v-card class="pa-5">
        <v-btn @click="resetModal = false" icon small class="px-0 close-btn"><v-icon>mdi-close</v-icon></v-btn>
        <div class="text-h6 font-weight-bold me-10">Do you really want to reset the staff password?</div>
        <div class="text-end mt-7">
          <v-btn depressed class="me-2 width100" max-width="120" height="38" color="gray lighten-2" @click="resetModal = false">
            <span class="f15 font-weight-bold gray--text">Cancel</span>
          </v-btn>
          <v-btn depressed class="ms-2 width100" max-width="120" height="38" color="primary" @click="reset">
            <span class="f15 font-weight-bold">Confirm</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editModal" max-width="600">
      <v-card class="pt-5 pb-12 px-3">
        <v-btn @click="editModal = false" icon small class="px-0 close-btn"><v-icon>mdi-close</v-icon></v-btn>
        <div class="mx-auto" style="max-width: 400px">
          <div class="text-center text-h5 font-weight-bold mb-7">Edit Profile</div>
          <v-hover v-slot="{ hover }">
            <v-avatar @click="changeAvatar" size="80" color="gray lighten-2" class="mx-auto d-block link">
              <v-img v-if="data.picture === null && !image" cover src="@/assets/icon/empty-user.svg" alt="avatar"></v-img>
              <v-img v-else cover :src="image || data.picture" alt="avatar"></v-img>
              <div :class="{ 'hover-ava': hover }" class="d-none white--text">Edit</div>
            </v-avatar>
          </v-hover>
          <div class="primary--text text--darken-2 mt-5">First name</div>
          <v-text-field
            class="field48"
            v-model="data.first_name"
            :error-messages="firstnameErrors"
            outlined
            dense
            required
            color="primary"
            height="48"
            maxlength="50"
          ></v-text-field>
          <div class="primary--text text--darken-2 mt-n2">Last name</div>
          <v-text-field
            class="field48"
            v-model="data.last_name"
            :error-messages="lastnameErrors"
            outlined
            dense
            required
            color="primary"
            height="48"
            maxlength="50"
          ></v-text-field>
          <div class="primary--text text--darken-2 mt-n2">Email Address</div>
          <v-text-field
            class="field48"
            v-model="data.email"
            :error-messages="emailErrors"
            outlined
            dense
            required
            height="48"
            color="primary"
          ></v-text-field>
          <div class="primary--text text--darken-2 mt-n2">Role</div>
          <v-select
            class="field48"
            v-model="data.role"
            :error-messages="roleErrors"
            :items="listRole"
            append-icon="mdi-chevron-down"
            item-text="text"
            item-value="value"
            outlined
            dense
            required
            height="48"
            color="primary"
          ></v-select>
          <div class="d-flex mt-4">
            <v-btn depressed class="me-2 width100" max-width="190" height="48" color="gray lighten-2" @click="editModal = false">
              <span class="f15 font-weight-bold gray--text">Cancel</span>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn depressed class="ms-2 width100" max-width="190" height="48" color="primary" @click="update">
              <span class="text-body-1 font-weight-bold">Save</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      editModal: false,
      resetModal: false,
      activeModal: false,
      listRole: [
        { value: 'admin', text: 'Admin' },
        { value: 'manager', text: 'Manager' },
      ],
      file: '',
      image: '',
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getStaff');
  },
  methods: {
    notifi(btn) {
      if (btn == 'confirm') {
        this.$notify({ message: 'Email confirmation was sent successfully', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'updatePhoto') {
        this.$notify({ message: 'Staff profile picture was successfully updated', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'update') {
        this.$notify({ message: 'Staff profile was successfully updated', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'reset') {
        this.$notify({
          message: 'Staff profile password was successfully updated, check your email',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'suspend') {
        this.$notify({ message: 'Staff profile was successfully suspended', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'active') {
        this.$notify({ message: 'Staff profile was successfully activated', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (this.error.find((i) => i == 'confirmation__too_often')) {
        this.$notify({
          message: 'Sorry, but email confirmation requests limit exceeded. Try again later',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (this.error.find((i) => i == 'email__already_confirmed')) {
        this.$notify({ message: 'Email is already confirmed', type: 'warning', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == '500') {
        this.$notify({
          title: 'Error',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async update() {
      this.error = [];
      const data = new Object();
      data.first_name = this.data.first_name;
      data.last_name = this.data.last_name;
      data.email = this.data.email;
      data.role = this.data.role;
      await this.$store
        .dispatch('updateStaff', data)
        .then(() => {
          this.editModal = false;
          this.notifi('update');
        })
        .catch((e) => {
          this.getDataError(e);
        });
    },
    changeAvatar() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.file = this.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            self.image = e.target.result;
            self.updatePhote();
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    async updatePhote() {
      this.error = [];
      const formData = new FormData();
      this.file ? formData.append('picture', this.file) : '';
      await this.$store.dispatch('setStaffPhoto', formData).then(() => {
        this.file = '';
        this.notifi('updatePhoto');
      });
    },
    async deletePhote() {
      this.error = [];
      await this.$store.dispatch('deleteStaffPhoto').then(() => {
        this.image = '';
        this.$store.dispatch('getStaff');
      });
    },
    async sendEmail() {
      this.error = [];
      await this.$store
        .dispatch('sendEmailStaff')
        .then(() => {
          this.notifi('confirm');
        })
        .catch((e) => {
          this.getDataError(e);
        });
    },
    async reset() {
      this.error = [];
      await this.$store
        .dispatch('resetPasswordStaff')
        .then(() => {
          this.resetModal = false;
          this.notifi('reset');
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.getDataError(e);
        });
    },
    async active() {
      this.error = [];
      await this.$store
        .dispatch(this.data.is_suspended ? 'restoreStaff' : 'suspendStaff')
        .then(() => {
          this.activeModal = false;
          this.data.is_suspended ? this.notifi('suspend') : this.notifi('active');
        })
        .catch((e) => {
          this.getDataError(e);
        });
    },
    getDataError(e) {
      this.error = e.response.data.error;
      if (this.error.find((i) => i == 'internal')) {
        this.notifi('500');
      }
    },
  },
  watch: {
    editModal() {
      this.file = '';
      this.image = '';
      this.error = [];
    },
  },
  computed: {
    data() {
      return this.$store.getters.staff;
    },
    firstnameErrors() {
      const errors = [];
      this.error.find((i) => i == 'first_name__required') && errors.push('Please enter your first name');
      this.error.find((i) => i == 'first_name__invalid') && errors.push('Provided first name is not valid');
      return errors;
    },
    lastnameErrors() {
      const errors = [];
      this.error.find((i) => i == 'last_name__required') && errors.push('Please enter your last name');
      this.error.find((i) => i == 'last_name__invalid') && errors.push('Provided last name is not valid');
      return errors;
    },
    emailErrors() {
      const errors = [];
      this.error.find((i) => i == 'email__required') && errors.push('Please enter your email address');
      this.error.find((i) => i == 'email__invalid') && errors.push('Provided email address is not valid');
      this.error.find((i) => i == 'email__exists') && errors.push('Provided email address is already registered. Please use another one');
      return errors;
    },
    roleErrors() {
      const errors = [];
      this.error.find((i) => i == 'role__required') && errors.push('Please select role');
      this.error.find((i) => i == 'role__invalid') && errors.push('Selected role is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setStaff');
  },
};
</script>
