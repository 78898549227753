<template>
  <div class="staff-list">
    <div class="d-flex align-center mb-7">
      <div class="text-sm-h5 text-h6 font-weight-bold">Staff Management</div>
      <v-spacer></v-spacer>
      <v-btn @click="modal = true" depressed large width="160" color="primary">
        <span class="f15 font-weight-bold">Add Staff</span>
      </v-btn>
    </div>
    <v-data-table
      class="d-md-block d-none"
      hide-default-footer
      fixed-header
      :headers="headers"
      :items="data.result"
      item-key="id"
      :items-per-page="-1"
    >
      <template v-slot:no-data v-if="!data.pages">
        <div class="d-flex flex-column align-center text-center my-15">
          <div style="max-width: 370px" class="f18 gray--text font-weight-medium my-6">No accounts added yet</div>
        </div>
      </template>
      <template v-slot:body="{ items }" v-else>
        <tbody>
          <tr v-for="item in items" :key="item.id" class="black--text" style="height: 56px" @click="clickItem(item.id)">
            <td>{{ item.first_name }} {{ item.last_name }}</td>
            <td>{{ item.email }}</td>
            <td class="text-capitalize">{{ item.role }}</td>
            <td>
              <v-card flat class="py-1 px-3 white--text" :class="item.is_suspended ? 'error' : 'success'" width="max-content">
                {{ item.is_suspended ? 'Inactive' : 'Active' }}
              </v-card>
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:footer>
        <v-divider v-if="data.pages > 1"></v-divider>
        <div class="mt-2 pb-2 px-3 d-flex align-center" v-if="data.pages > 1">
          <v-spacer></v-spacer>
          <v-pagination
            color="primary"
            prev-icon="mdi-arrow-left-thin"
            next-icon="mdi-arrow-right-thin"
            @input="getData"
            v-model="page"
            :length="data.pages"
            :total-visible="5"
          ></v-pagination>
        </div>
      </template>
    </v-data-table>
    <div class="d-md-none">
      <div class="d-flex flex-column align-center text-center" v-if="!data.pages">
        <div style="max-width: 370px" class="f18 gray--text font-weight-medium my-6">No accounts added yet</div>
      </div>
      <div v-else v-for="item in data.results" :key="item.id">
        <div class="d-flex align-center f15">
          <div>Full name</div>
          <v-spacer></v-spacer>
          <div class="gray--text">{{ item.first_name }} {{ item.last_name }}</div>
        </div>
        <div class="d-flex align-center f15">
          <div>Email</div>
          <v-spacer></v-spacer>
          <div class="gray--text">{{ item.email }}</div>
        </div>
        <div class="d-flex align-center f15">
          <div>Role</div>
          <v-spacer></v-spacer>
          <div class="gray--text">{{ item.role }}</div>
        </div>
        <div class="d-flex align-center f15">
          <div>Status</div>
          <v-spacer></v-spacer>
          <v-card flat class="py-1 px-3 white--text" :class="item.is_suspended ? 'error' : 'success'" width="max-content">
            {{ item.is_suspended ? 'Inactive' : 'Active' }}
          </v-card>
        </div>
      </div>
      <div class="mt-2 pb-2 mx-auto" v-if="data.pages > 1">
        <v-pagination
          color="primary"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          @input="getData"
          v-model="page"
          :length="data.pages"
          :total-visible="5"
        ></v-pagination>
      </div>
    </div>
    <v-dialog v-model="modal" max-width="600">
      <v-card class="pt-5 pb-12 px-3">
        <v-btn @click="modal = false" icon small class="px-0 close-btn"><v-icon>mdi-close</v-icon></v-btn>
        <div class="mx-auto" style="max-width: 400px">
          <div class="text-center text-h5 font-weight-bold mb-7">Add Staff</div>
          <div class="primary--text text--darken-2 mt-5">First name</div>
          <v-text-field
            class="field48"
            v-model="modalData.first_name"
            :error-messages="firstnameErrors"
            outlined
            dense
            required
            color="primary"
            height="48"
            maxlength="50"
          ></v-text-field>
          <div class="primary--text text--darken-2 mt-n2">Last name</div>
          <v-text-field
            class="field48"
            v-model="modalData.last_name"
            :error-messages="lastnameErrors"
            outlined
            dense
            required
            color="primary"
            height="48"
            maxlength="50"
          ></v-text-field>
          <div class="primary--text text--darken-2 mt-n2">Email Address</div>
          <v-text-field
            class="field48"
            v-model="modalData.email"
            :error-messages="emailErrors"
            outlined
            dense
            required
            height="48"
            color="primary"
          ></v-text-field>
          <div class="primary--text text--darken-2 mt-n2">Role</div>
          <v-select
            class="field48"
            v-model="modalData.role"
            :error-messages="roleErrors"
            :items="listRole"
            append-icon="mdi-chevron-down"
            item-text="text"
            item-value="value"
            outlined
            dense
            required
            height="48"
            color="primary"
          ></v-select>
          <div class="d-flex mt-4">
            <v-btn depressed class="me-2 width100" max-width="190" height="48" color="gray lighten-2" @click="modal = false">
              <span class="f15 font-weight-bold gray--text">Cancel</span>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn depressed class="ms-2 width100" max-width="190" height="48" color="primary" @click="create">
              <span class="text-body-1 font-weight-bold">Create</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      modal: false,
      modalData: {},
      listRole: [
        { value: 'admin', text: 'Admin' },
        { value: 'manager', text: 'Manager' },
      ],
      search: this.$route.query.src || '',
      page: +this.$route.query.page || 1,
      headers: [
        { text: 'Full name', value: 'first_name', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Role', value: 'role', sortable: false },
        { text: 'Status', value: 'is_suspended', sortable: false },
      ],
      statusError: '',
      error: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    notifi(btn) {
      if (btn == 'search400') {
        this.$notify({
          title: 'Error',
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          title: 'Error',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'create') {
        this.$notify({
          title: 'Done!',
          message: 'Staff profile was successfully created',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    clickItem(id) {
      this.$router.push(`/staff/${id}`);
    },
    async create() {
      this.error = [];
      const data = new Object();
      data.first_name = this.modalData.first_name;
      data.last_name = this.modalData.last_name;
      data.email = this.modalData.email;
      data.role = this.modalData.role;
      await this.$store
        .dispatch('addStaff', data)
        .then(() => {
          this.getData();
          this.modal = false;
          this.notifi('create');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async getData() {
      this.statusError = '';
      this.error = [];
      await this.$store
        .dispatch('getStaffList', {
          search: this.search,
          page: this.page,
        })
        .catch((e) => {
          this.getDataError(e);
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error.find((i) => i == 'page__out_of_bounds')) {
        this.page = 1;
        this.getData();
        // } else if (this.statusError >= 400 && this.statusError < 500) {
        //   this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
  },
  watch: {
    modal() {
      this.error = [];
      this.modalData = {};
    },
  },
  computed: {
    data() {
      return this.$store.getters.staffList;
    },
    firstnameErrors() {
      const errors = [];
      this.error.find((i) => i == 'first_name__required') && errors.push('Please enter your first name');
      this.error.find((i) => i == 'first_name__invalid') && errors.push('Provided first name is not valid');
      return errors;
    },
    lastnameErrors() {
      const errors = [];
      this.error.find((i) => i == 'last_name__required') && errors.push('Please enter your last name');
      this.error.find((i) => i == 'last_name__invalid') && errors.push('Provided last name is not valid');
      return errors;
    },
    emailErrors() {
      const errors = [];
      this.error.find((i) => i == 'email__required') && errors.push('Please enter your email address');
      this.error.find((i) => i == 'email__invalid') && errors.push('Provided email address is not valid');
      this.error.find((i) => i == 'email__exists') && errors.push('Provided email address is already registered. Please use another one');
      return errors;
    },
    roleErrors() {
      const errors = [];
      this.error.find((i) => i == 'role__required') && errors.push('Please select role');
      this.error.find((i) => i == 'role__invalid') && errors.push('Selected role is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setStaffList');
  },
};
</script>
