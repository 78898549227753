import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/dashboard',
      name: 'Home',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Home.vue'),
    },
    {
      path: '/profile',
      name: 'Profile',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Profile.vue'),
    },
    {
      path: '/staff',
      name: 'Staff Management',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/StaffManagList.vue'),
    },
    {
      path: '/staff/:staffId',
      name: 'Edit staff account',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/StaffManag.vue'),
    },
    {
      path: '/metrics',
      name: 'Metrics',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Metrics.vue'),
    },
    {
      path: '/sign-in',
      name: 'Sign in',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Login.vue'),
    },
    {
      path: '/sign-in/otp',
      name: 'OTP code',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Otp.vue'),
    },
    {
      path: '/sign-in/otp-recovery',
      name: 'OTP recovery code',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/OtpRecovery.vue'),
    },
    {
      path: '/forgot-password',
      name: 'Forgot password',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/NewPass.vue'),
    },
    {
      path: '/reset-password',
      name: 'Password update',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/CreatePass.vue'),
    },
    {
      path: '/email-not-confirmed',
      name: 'Email not confirmation',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/NotConfirm.vue'),
    },
    {
      path: '/account-suspended',
      name: 'Account suspended',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Suspended.vue'),
    },
    {
      path: '/email-confirmation',
      name: 'Email confirmation',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Confirm.vue'),
    },
    {
      path: '/sign-in-tries-limit',
      name: 'Sign-in attempt limit',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Fail.vue'),
    },
    {
      path: '*',
      redirect: { name: 'Home' },
    },
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const currentUser = sessionStorage.getItem('jwt_token');
  const requireAuth = to.matched.some((record) => record.meta.auth);

  if (requireAuth && !currentUser) {
    next({ name: 'Sign in', query: { from: to.fullPath } });
  } else {
    next();
  }
});

export default router;
