<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import EmptyLayout from '@/layouts/EmptyLayout';
import MainLayout from '@/layouts/MainLayout';

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || 'empty') + '-layout';
    },
  },
  components: {
    EmptyLayout,
    MainLayout,
  },
};
</script>

<style lang="scss">
@import 'chartist/dist/scss/chartist.scss';
#app {
  * {
    font-family: 'Cairo', sans-serif !important;
  }
  .text-h4 {
    font-size: 2rem !important;
  }
  .hover-ava {
    display: flex !important;
    align-items: end;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 15px;
    font-weight: 600;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 0.01%,
      rgba(4, 4, 4, 0.063) 66.05%,
      rgba(46, 46, 46, 0.7) 100%
    );
  }
  .v-data-table {
    border: 1px solid var(--v-gray-lighten2);
    border-radius: 6px;
    thead {
      tr {
        height: 50px;
        th {
          color: var(--v-primary-darken2);
          background-color: var(--v-gray-lighten2);
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
    tbody {
      td {
        font-size: 15px;
        font-weight: 500;
        color: #000;
      }
    }
  }
}
body,
#app {
  background: var(--v-gray-lighten3);
}
a {
  text-decoration: none;
}
.f15 {
  font-size: 15px;
}
.f18 {
  font-size: 18px;
}
.f28 {
  font-size: 28px;
}
.f30 {
  font-size: 30px;
}
.f36 {
  font-size: 36px;
}
.text-600 {
  font-weight: 600 !important;
}
.wrap {
  max-width: 932px;
  width: 100%;
  margin: 0 auto;
}
.width100 {
  width: 100%;
}
.width0 {
  width: 0;
}
.height100 {
  height: 100%;
}
.link {
  cursor: pointer;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
}
.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
}
.error-btn {
  background-color: var(--v-error-lighten5);
}
.success-btn {
  background-color: var(--v-success-lighten5);
}
.v-btn__content {
  text-transform: none;
  letter-spacing: initial !important;
  opacity: 1 !important;
}
.center-input input {
  padding: 0 !important;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
}
.field48 .v-input__append-inner,
.field48 .v-input__prepend-inner {
  margin-top: 16px !important;
}
.field48.v-select .v-input__append-inner {
  margin-top: 14px !important;
}
</style>
